<template>
  <div id="app" :class="{ 'dark-theme': isDarkTheme }">
    <button @click="toggleTheme" class="theme-toggle">
      {{ isDarkTheme ? '☀️' : '🌙' }}
    </button>
    <RandomExample />
  </div>
</template>

<script>
import RandomExample from './components/RandomExample.vue';

export default {
  name: 'App',
  components: {
    RandomExample
  },
  data() {
    return {
      isDarkTheme: false
    };
  },
  methods: {
    toggleTheme() {
      this.isDarkTheme = !this.isDarkTheme;
      document.body.classList.toggle('dark-theme', this.isDarkTheme);
    }
  },
  mounted() {
    // Проверяем предпочтения пользователя при загрузке
    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDarkScheme.matches) {
      this.isDarkTheme = true;
      document.body.classList.add('dark-theme');
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  transition: all 0.3s ease;
}

.dark-theme {
  background-color: #1a1a1a;
  color: #ffffff;
}

.theme-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.dark-theme .theme-toggle {
  color: #ffffff;
}
</style>

