<template>
  <div class="random-example">
    <h2>Your Random Example</h2>
    
    <div class="input-group">
      <label for="operation">Choose Operation: </label>
      <select v-model="selectedOperation" id="operation">
        <option value="addition">Addition</option>
        <option value="subtraction">Subtraction</option>
        <option value="multiplication">Multiplication</option>
        <option value="division">Division</option>
        <option value="random">Random</option>
      </select>
    </div>

    <div class="input-group">
      <label for="num1-digits">Number of digits for first number:</label>
      <input v-model.number="num1Digits" type="number" id="num1-digits" min="1" placeholder="Enter digits for num1" />
    </div>

    <div class="input-group">
      <label for="num2-digits">Number of digits for second number:</label>
      <input v-model.number="num2Digits" type="number" id="num2-digits" min="1" placeholder="Enter digits for num2" />
    </div>

    <p class="example">{{ example }}</p>
    
    <div class="input-group">
      <input v-model="userAnswer" type="number" placeholder="Enter your answer" />
    </div>

    <button @click="checkAnswer" class="btn">Submit Answer</button>

    <p v-if="feedbackMessage" :class="['feedback', { 'correct': feedbackMessage.includes('Correct') }]">
      {{ feedbackMessage }}
    </p>

    <button @click="generateExample" class="btn">Generate New Example</button>

    <div class="input-group">
      <input v-model.number="firstNumber" type="number" placeholder="Set first number (optional)" />
      <button @click="generateExample" class="btn">Set and Generate Example</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const apiUrl = process.env.NODE_ENV === 'production' 
  ? 'https://mathexamples.ru/api' 
  : 'http://localhost:5000/api';

console.log('Current API URL:', apiUrl);

export default {
  name: 'RandomExample',
  data() {
    return {
      firstNumber: null,  // User can set this
      num1: null,
      num2: null,
      example: '',
      userAnswer: '',
      feedbackMessage: '',
      selectedOperation: 'random',
      num1Digits: 1,
      num2Digits: 1,
      correctAnswer: null,
      isServerError: false
    };
  },
  methods: {
    generateExample() {
      console.log('Generating example with params:', {
        operation: this.selectedOperation,
        num1Digits: this.num1Digits,
        num2Digits: this.num2Digits,
        firstNumber: this.firstNumber
      });

      axios.post(`${apiUrl}/generate-example`, {
        operation: this.selectedOperation,
        num1Digits: this.num1Digits,
        num2Digits: this.num2Digits,
        firstNumber: this.firstNumber
      })
      .then(response => {
        console.log('Server response:', response.data);
        this.example = response.data.example;
        this.correctAnswer = response.data.correctAnswer;
        this.isServerError = false;
      })
      .catch(error => {
        console.error('Detailed error:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        this.example = 'Error: Server not available';
        this.isServerError = true;
      });

      this.userAnswer = '';
      this.feedbackMessage = '';
    },
    checkAnswer() {
      if (this.isServerError) {
        this.feedbackMessage = 'Cannot check answer: Server not available';
        return;
      }
      console.log('Checking answer:', {
        userAnswer: this.userAnswer,
        correctAnswer: this.correctAnswer
      });

      axios.post(`${apiUrl}/check-answer`, {
        userAnswer: this.userAnswer,
        correctAnswer: this.correctAnswer
      })
      .then(response => {
        console.log('Check answer response:', response.data);
        if (response.data.isCorrect) {
          this.feedbackMessage = 'Correct!';
        } else {
          this.feedbackMessage = `Incorrect. The correct answer is ${response.data.correctAnswer}.`;
        }
      })
      .catch(error => {
        console.error('Error checking answer:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        this.feedbackMessage = 'Error: Cannot check answer';
      });
    }
  },
  mounted() {
    console.log('Component mounted');
    this.generateExample();
  }
};
</script>

<style scoped>
.random-example {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.dark-theme .random-example {
  background-color: #2a2a2a;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.dark-theme h2 {
  color: #ffffff;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #2c3e50;
}

.dark-theme label {
  color: #ffffff;
}

input, select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: #ffffff;
  color: #2c3e50;
}

.dark-theme input, .dark-theme select {
  background-color: #3a3a3a;
  color: #ffffff;
  border-color: #555;
}

.btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #2980b9;
}

.dark-theme .btn {
  background-color: #2980b9;
}

.dark-theme .btn:hover {
  background-color: #3498db;
}

.example {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
  color: #2c3e50;
}

.dark-theme .example {
  color: #ffffff;
}

.feedback {
  margin-top: 10px;
  font-weight: bold;
  color: #e74c3c;
}

.feedback.correct {
  color: #2ecc71;
}

.dark-theme .feedback {
  color: #ff6b6b;
}

.dark-theme .feedback.correct {
  color: #5cdb95;
}
</style>

